<template>
    <div class="modal is-active">
        <div @click.self="$emit('toggle_stock_modal')" class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Shto gjendje</p>
                <button @click.prevent="$emit('toggle_stock_modal')" class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body modal-card-body-custom px-0 pb-0">
                <div class="columns border-b border-slate-200 mx-0 px-2 mb-0">
                    <div class="column is-4 pl-0">
                        <span>Zgjidh magazinen</span>
                    </div>
                    <div class="column pr-0">
                        <div class="">
                                <!-- :model-value="selected_warehouse.guid" -->
                            <VueMultiselect
                                v-model="selected_warehouse"
                                :options="warehouses"
                                label="name"
                                placeholder="Magazina"
                                track-by="guid" >
                            </VueMultiselect>
                            <!-- <div class="select is-small is-fullwidth">
                                <select v-model="selected_warehouse">
                                    <option v-for="(warehouse, indexa) in warehouses" :key="indexa"
                                        :value="warehouse.guid">
                                        {{warehouse.name}}
                                    </option>
                                </select>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class=" border-b border-slate-200 py-4 px-2 bg-lime-50">
                    <div class="columns my-0 mx-0">
                        <div class="column is-4 px-0">
                            <div style="white-space: nowrap; padding-right: 2px;">Kerko dhe zgjidh produkte </div>
                        </div>
                        <div class="column px-0">
                            <div class="pl-2 is-flex-grow-1">
                                <div class="field has-addons">
                                    <p class="control is-expanded">
                                        <input v-model="search_product_val" @keyup.enter="search_product()" class="input is-small" type="text" placeholder="Kerko">
                                    </p>
                                    <p class="control">
                                        <a v-if="!loading_search" @click.prevent="search_product" class="button is-small is-success"> <i class="fa-solid fa-magnifying-glass"></i> </a>
                                        <a v-else href="" @click.prevent="() => {}" class="button is-success is-small">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table v-if="product_search_results.length > 0" class="is-fullwidth is-narrow table bg-lime-50">
                        <thead>
                            <tr>
                                <th class="p-1">Emri</th>
                                <th class="p-1">Kodi</th>
                                <th class="p-1" width="35%">Sasia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, index) in product_search_results" :key="index">
                                <td class="p-1">
                                    <span> {{ product.name }} </span>
                                    <br>
                                    <small class="text-slate-400">
                                        Kategoria: {{ product.category_name }}
                                    </small>

                                </td>
                                <td class="p-1">{{ product.code }}</td>
                                <td class="p-1">
                                    <div class="field has-addons">
                                        <!-- <p class="control">
                                            <span class="select is-small">
                                                <select>
                                                    <option>Cope</option>
                                                    <option>Metra</option>
                                                    <option>Litra</option>
                                                </select>
                                            </span>
                                        </p> -->
                                        <p class="control">
                                            <a class="button is-static is-small is-capitalized">
                                                {{product.unit_name}}
                                                <!-- {{ product.unit == 1 ? 'Cope' : product.unit == 2 ? 'Liter' : product.unit == 3 ? 'Meter' : product.unit == 4 ? 'Kile' : '' }} -->
                                            </a>
                                        </p>
                                        <p class="control is-expanded">
                                            <input v-model="product.amount" @keyup.enter="add_stock_to_lost(product)" class="input is-small" type="text" placeholder="Sasia">
                                        </p>
                                        <p class="control">
                                            <a v-if="product.amount > 0" @click.prevent="add_stock_to_lost(product)" class="button is-small is-success"><i class="fa-solid fa-check"></i></a>
                                            <a v-else @click.prevent="() => {}" class="button is-small is-success" disabled><i class="fa-solid fa-check"></i></a>
                                        </p>
                                    </div>
                                    <!-- <a href="" class="buttin is-primary is-small is-fullwidth"><i class="fa-solid fa-plus"></i></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div  class=" px-2 is-flex is-flex-direction-column py-4 bg-emerald-50 is-flex-grow-1">
                    <p class="has-text-centered">Lista e produkteve per tu shtuar</p>
                    <table v-if="new_stock_list.length > 0" class=" table is-fullwidth bg-emerald-50">
                        <thead>
                            <tr>
                                <th class="p-1">Produkti</th>
                                <th class="p-1">Kodi</th>
                                <th class="p-1" width="10%">Sasia</th>
                                <th class="has-text-right p-1" width="10%">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, index) in new_stock_list" :key="index">
                                <td class="p-1">{{ product.name }}</td>
                                <td class="p-1">{{ product.code }}</td>
                                <td class="p-1">{{ product.amount }}</td>
                                <td class="has-text-right p-1">
                                    <a @click.prevent="remove_stock_from_list(product)" href="" class="buttin is-primary is-small is-fullwidth"><i class="fa-regular fa-trash-can"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="has-text-centered"> <small>Per momentin lista eshte bosh.</small> </p>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button @click.prevent="$emit('toggle_stock_modal')" class="button is-small">Anullo</button>
                <div class="ml-auto">
                    <button v-if="new_stock_list.length > 0 && !loading_confirmation && selected_warehouse != ''" @click.prevent="confirm_create_stock()" class="button is-success ml-auto is-small">Konfirmo</button>
                    <button v-if="loading_confirmation" @click.prevent="() => {}" class="button is-success ml-auto is-small"><i class="fa fa-spinner fa-spin"></i></button>
                    <span v-if="new_stock_list.length == 0" class="pr-2 mr-2 border-r border-slate-300">
                        <i class="fa-solid fa-triangle-exclamation mr-1 text-slate-400"></i> Shto produkte ne liste
                    </span>
                    <span v-if="selected_warehouse == ''">
                        <i class="fa-solid fa-triangle-exclamation mr-1 text-slate-400"></i> Zgjidh nje magazine
                    </span>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
import VueMultiselect from 'vue-multiselect'
export default {
    components: { VueMultiselect },
    created(){
        this.get_add_modal_data()
    },
    data() {
        return {
            search_product_val: '',
            product_search_results: [],
            warehouses: [],
            selected_warehouse: '',
            new_stock_list: [],
            loading_search: false,
            loading_confirmation: false
        }
    },
    methods: {
        get_add_modal_data(){
            Api(true).post('/stock/get/add/modal/data')
            .then(r => {
                this.warehouses = r.data.warehouses
            })
        },
        async search_product(){
            if(this.search_product_val != ''){
                this.loading_search = true
                this.product_search_results = []
                await Api(true).post('/stock/search/product', {search_val: this.search_product_val})
                .then(res => {
                    this.product_search_results = [...res.data.map(d => {
                        d.amount = 0
                        return d
                    })]
                    this.loading_search = false
                })
                this.loading_search = false
            }
        },
        add_stock_to_lost(p){
            if(p.amount > 0){
                this.new_stock_list.unshift(p)
                this.$toast.success('Produkti ' + p.name + ' u shtua ne liste.')
                this.product_search_results = []
                this.search_product_val = ''
            }

        },
        remove_stock_from_list(p){
            this.new_stock_list = [...this.new_stock_list.filter(s => s.guid != p.guid)]
        },
        async confirm_create_stock(){

            this.loading_confirmation = true

            await Api(true).post('/stock/create/stock', {
                stock_list: this.new_stock_list, 
                selected_warehouse: this.selected_warehouse 
            }).then(() => {
                // this.loading_confirmation = false
                this.$toast.success('Gjendja u shtua me sukses.', {
                    duration: 3000,
                })
                this.$parent.get_stock()
                this.$parent.get_user_pos()

                this.selected_warehouse = {}
                this.search_product_val = '',
                this.product_search_results = [],
                this.new_stock_list = []
                this.loading_confirmation = false


                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000);
            }, () => {
                this.loading_confirmation = false
                this.$toast.error('Dicka nuk punoi.')
            })
        }
        
    },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.modal-card-body-custom{
    display: flex;
    min-height: 300px;
    flex-direction: column;
    align-items: stretch;
}
</style>