<template>
  <div class="modal is-active modal-lg" id="modal">
    <div @click.self="$emit('toggle_stock_info_modal')" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>{{selected_stock_product.name}}:</b> 
          <span class="pl-2 text-blue-800">[ Ne Process ]</span>
        </p>
        <button @click.prevent="$emit('toggle_stock_info_modal')" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body p-2">
        <table class="table is-striped is-narrow is-hoverable is-fullwidth text-sm">
          <thead>
            <tr>
              <th width="80px">Document</th>
              <th>POS</th>
              <th>Mag.Dalese</th>
              <th>Mag.Hyrese</th>
              <th>Krijuar me</th>
              <th width="10px">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, index) in draft_list" :key="index">
              <td>
                <div class="flex items-center flex-col justify-center text-center">
                  {{ d.process_number }}
                  <div class="font-thin text-xs uppercase">{{ d.process_name }}</div>
                </div>
              </td>
              <td>{{ d.pos_name }}</td>
              <td>{{ d.from_warehouse_name }}</td>
              <td>{{ d.to_warehouse_name }}</td>
              <td>
                {{ d.created_at ? date_filter(d.created_at) : '' }}
              </td>
              <td>
                <a @click.prevent="open_document(d)" href="" class="button is-primary is-small">
                  <i class="fa-regular fa-share-from-square"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">

      </footer>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { useDocumentStore } from "@/stores/document";
import { storeToRefs } from 'pinia';
import moment from 'moment'
export default {
  props: ['selected_stock_product', 'selected_stock_warehouse'],
  data() {
    return {
      draft_list: [],
      document: storeToRefs(useDocumentStore()),
    }
  },
  created() {
    this.get_stock_modal_data()
  },
  methods: {
    get_stock_modal_data() {
      Api(true).post("stock/get/pending/info", {
        warehouse: this.selected_stock_warehouse,
        product: this.selected_stock_product
      }).then(res => {
        this.draft_list = res.data
      })
    },
    open_document(d) {
      let routeData = this.$router.resolve({
        path: '/process/document/' + d.process_id + '/' + d.guid
      });
      window.open(routeData.href, '_blank');
    },
    date_filter(date) {
      return moment(date).format('DD/MM/YYYY hh:mm')
    }
  },

}
</script>

<style scoped>
.modal-card-head {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dbdbdb;
  padding: 14px 8px;
}

.modal-card-title {
  font-size: 14px !important;
}
</style>
