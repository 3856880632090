<template>
  <div>
    <div class="is-capitalized is-size-5 mb-2 is-flex items-end">
      <span> Gjendje </span>
      <div class="ml-auto flex flex-col">
        <small class="is-size-7 text-gray-400">
          Pika e shitjes
        </small>
        <div class="select is-small">
          <select v-model="selected_pos" @change="get_pos_warehouses(selected_pos)">
            <option v-for="(pos, index) in pos_list" :key="index" :value="pos.guid" >{{ pos.name }}</option>
          </select>
        </div>
      </div>
      <div class="ml-3 flex flex-col">
        <small class="is-size-7">&nbsp;</small>
        <div class="control has-icons-left">
          <input v-model="search_val" class="input is-small" type="text" placeholder="Kerko">
          <span class="icon is-small is-left">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <a href="" class="ml-auto" @click.prevent="get_stock()" >
        <i class="fa fa-refresh fa-xs text-slate-300 mx-2 is-clickable"></i>
      </a>
    </div>

    <div class="table-container" style="margin-right:40px;" v-if="selected_pos != null">
      <table class="table is-bordered is-stripped is-narrow is-fullwidth stock-table is-striped">
        <thead>
          <tr>
            <th>*</th>
            <td v-for="(warehouse, index) in warehouses_list" :key="index" colspan="4"
              style="font-family: 'Karla', sans-serif, FontAwesome;">
              {{ warehouse.name }}
            </td>
          </tr>
          <tr>
            <td></td>
            <template v-for="(warehouse, index) in warehouses_list" :key="index">
              <td title="Gjendja totale ne stok, nuk konsiderohet gjendja ne proces ose ditar">Stok</td>
              <td title="Gjendja e rezervuar ne ditar qe akoma nuk ka kaluar per procesim">Ditar</td>
              <td title="Gjendja ne proces qe akoma nuk ka prekur stokun (hyrje/dalje)" >Proces</td>
              <td title="Gjendja e disponueshme per shitje">Disp.</td>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product, indexa in filtered_products" :key="indexa">
            <td style="font-family: 'Karla', sans-serif, FontAwesome;">{{ product.name }}</td>
            <template v-for="(warehouse, index) in warehouses_list" :key="index">
              <td style="vertical-align:middle;">
                <span :class="{ 'text-slate-300': product[warehouse.guid] == 0 }" title="Gjendje ne stok">
                  {{ product[warehouse.guid] }}
                </span>
              </td>
              <td style="vertical-align:middle;" class="text-right ">
                <span v-if="product[warehouse.guid + '_diary_out'] > 0"
                  :class="{ 'text-red-600': product[warehouse.guid + '_diary_out'] > 0 }"
                  class="is-pulled-right is-clickable" title="Dalje ne process">
                  -{{ product[warehouse.guid + '_diary_out'] }}
                </span>
                <span v-if="product[warehouse.guid + '_diary_in']"
                  :class="{ 'text-blue-600': product[warehouse.guid + '_diary_in'] > 0 }"
                  class="is-pulled-right is-clickable" title="Hyrje ne process">
                  +{{ product[warehouse.guid + '_diary_in'] }}
                </span>

              </td>
              <td style="vertical-align:middle;" class="">
                <span @click="toggle_stock_info_modal(product, warehouse)" v-if="product[warehouse.guid + '_out'] > 0"
                  :class="{ 'text-red-600': product[warehouse.guid + '_out'] > 0 }"
                  class="is-pulled-right is-clickable" title="Dalje ne process">
                  -{{ product[warehouse.guid + '_out'] }}
                </span>
                <span @click="toggle_stock_info_modal(product, warehouse)" v-if="product[warehouse.guid + '_in']"
                  :class="{ 'text-blue-600': product[warehouse.guid + '_in'] > 0 }"
                  class="is-pulled-right is-clickable" title="Hyrje ne process">
                  +{{ product[warehouse.guid + '_in'] }}
                </span>
              </td>
              <td style="vertical-align:middle;" class="  "
                :class="{
                  'font-semibold text-lg': product[warehouse.guid] - product[warehouse.guid + '_diary_out'] - product[warehouse.guid + '_out'] > 0,
                  'text-slate-300 ': product[warehouse.guid] - product[warehouse.guid + '_diary_out'] - product[warehouse.guid + '_out'] == 0 
                }">
                <span class="text-right float-right">
                  {{ product[warehouse.guid] - product[warehouse.guid + '_diary_out'] - product[warehouse.guid + '_out'] }}
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <div class="text-gray-300 is-size-7 flex justify-end">
        {{ filtered_products.length }} nga {{ products_list.length }}
      </div>
    </div>
    <div class="flex items-center justify-center w-full h-screen flex-col" v-else>
      <i class="fa fa-spinner fa-spin fa-2x text-slate-300"></i>
      <span class="text-slate-300">Faqja po ngarkohet...</span>
    </div>
    <add-stock-modal v-if="show_add_stock_modal" @toggle_stock_modal="toggle_stock_modal"></add-stock-modal>
    <stock-info-modal v-if="show_stock_info_modal" :selected_stock_product=selected_stock_product
      :selected_stock_warehouse=selected_stock_warehouse
      @toggle_stock_info_modal="toggle_stock_info_modal"></stock-info-modal>
  </div>
</template>

<script>
import Api from "@/services/Api";
import WarehousesServices from "@/services/Warehouse";
import ProductsServices from "@/services/Product";
import addStockModal from "./components/add-stock-modal";
import StockInfoModal from "./components/stock-info-modal";
import permissionMixin from "@/mixins/permissions-mixin.js";
// import { storeToRefs } from 'pinia';
// import { useDocumentStore } from "@/stores/document";
export default {
  mixins: [permissionMixin],
  components: {
    addStockModal,
    StockInfoModal
  },
  data() {
    return {
      warehouses: [],
      products: [],
      stock: [],
      pos_list: [],
      selected_pos: null,
      warehouses_of_pos: [],
      search_val: '',
      pending_stock: [],
      diary_stock: [],
      show_add_stock_modal: false,
      show_stock_info_modal: false,
      // document: storeToRefs(useDocumentStore()),
      selected_stock_product: {},
      selected_stock_warehouse: {},
      current_user: {}
    }
  },
  async created() {
    if (!this.am_allowed('stock.can_see')) {
      window.location.href = '/home'
      // this.$router.replace({path: '/home'})
    } else {
      this.warehouses = await WarehousesServices.getWarehouses()
      this.products = await ProductsServices.getProducts()

      await this.get_stock(),
      this.current_user = JSON.parse(localStorage.user)
      await this.get_user_pos()
    }
  },
  methods: {
    async get_stock() {
      this.stock = [] 
      this.pending_stock = []
      this.diary_stock = []
      this.products = await ProductsServices.getProducts()

      await this.get_pos_warehouses()
      await Api(true).post("stock/get/all")
        .then(r => {
          this.stock = r.data[0]
          this.pending_stock = r.data[1]
          this.diary_stock = r.data[2]

          // attach to each product the warehouse availability, 
          // default 0  
          this.products = [...this.products
          .filter(p => {
            // is in this.stock or is in this.pending_stock
            return this.stock.find(s => s.product_id == p.guid) || this.pending_stock.find(s => s.product_id == p.guid)
          })
          .map(p => {
            this.warehouses.map(w => {
              // p[w.guid] = this.get_product_stock(p.guid, w.guid)
              // p[w.guid + '_out'] = this.get_product_pending_stock(p.guid, w.guid, 'out')
              // p[w.guid + '_in'] = this.get_product_pending_stock(p.guid, w.guid, 'in')
              
              p = {
                ...p, 
                [w.guid]: this.get_product_stock(p.guid, w.guid),
                [w.guid + '_out']: this.get_product_pending_stock(p.guid, w.guid, 'out'),
                [w.guid + '_in']: this.get_product_pending_stock(p.guid, w.guid, 'in'),
                [w.guid + '_diary_in']: this.get_product_pending_stock(p.guid, w.guid, 'diary_in'),
                [w.guid + '_diary_out']: this.get_product_pending_stock(p.guid, w.guid, 'diary_out')
              }
            })
            return p
          })]
        })
    },
    async get_user_pos() {
      await Api(true).post('/invoice/get/assigned/pos', { user: this.current_user })
      .then(r => {
        this.pos_list = r.data
        this.selected_pos = this.pos_list[0].guid
        this.get_pos_warehouses()
      })
    },
    get_product_stock(p, w) {
      var amount = 0
      this.stock.map(s => {
        if (s.product_id == p && s.warehouse_id == w)
          amount += Number(s.amount)
      })
      return amount
    },
    get_product_pending_stock(p, w, type) {
      var amount = 0
      if(['in', 'out'].includes(type) ){
        this.pending_stock.map(s => {
          if (s.product_id == p && s['warehouse_' + type] == w)
            if(s.amount)
              amount += Number(s.amount)
        })
      }
      else if(['diary_in', 'diary_out'].includes(type) ){
        this.diary_stock.map( s => {
          if (s.entity_id == p && s['warehouse_' + type] == w){
            if(s.amount){
              amount += Number(s.amount)
            }
          }
        })
      }
      return amount
    },
    async get_pos_warehouses() {
      if (this.selected_pos) {
        Api(true).post("pos/warehouses/list", { pos_id: this.selected_pos })
          .then(res => {
            this.warehouses_of_pos = res.data
          })
      }
    },
    toggle_stock_modal() {
      this.show_add_stock_modal = !this.show_add_stock_modal
    },
    toggle_stock_info_modal(p, w) {
      this.show_stock_info_modal = !this.show_stock_info_modal
      if (this.show_stock_info_modal) {
        this.selected_stock_product = p
        this.selected_stock_warehouse = w
      }
      else {
        this.selected_stock_product = {}
        this.selected_stock_warehouse = {}
      }
    },

    // show_stock_info_modal
    // available_stock(product){
    //   console.log(product)
    // }

  },
  computed: {
    warehouses_list() {
      if (this.selected_pos == null) {
        return this.warehouses
      }
      else {
        return this.warehouses.filter(w => {
          var found = false
          this.warehouses_of_pos.map(wp => {
            if (wp.warehouse_id == w.guid) {
              found = true
            }
          })
          return found
        })
        .sort((a, b) => {
          // sort by type_id 
          if(this.warehouses_of_pos.find(w => w.warehouse_id == a.guid).type_id > this.warehouses_of_pos.find(w => w.warehouse_id == b.guid).type_id){
            return 1
          }
          else{
            return -1
          }
        })
      }
    },
    products_list() {
      return this.products
    },
    filtered_products() {
      return this.products_list
        // has stock 
        .filter(product => {
          var has_stock = false
          this.warehouses_list.map(w => {
            if (product[w.guid] > 0 || product[w.guid + '_out'] > 0 || product[w.guid + '_in'] > 0) {
              has_stock = true
            }
          })
          return has_stock
        })
        .filter(product => JSON.stringify(product).toLowerCase().includes(this.search_val != '' ? this.search_val : null)).slice(0, 10)
    }
  }
}
</script>

<style scoped>
/* .stock-table{
        font-family: 'Karla', sans-serif, FontAwesome;
    } */
.ubuntu-table-values > span {

  /* font-family: 'Squada One', sans-serif, FontAwesome; */
}

th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  background-color: #f1f1f1;
  z-index: 1;
  min-width: 200px;
}


/* add to table body rows hover sky color */
tr:hover {
  background-color: aliceblue !important;
}
</style>
